.footerLinks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2%;
}

.footerLinksGroup {
  width: 49%;
}

.footerLinks a {
  display: block;
  color: var(--ant-color-text);
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.footerLinks a:hover {
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .footerLinksGroup {
    width: 100%;
  }
}