:global(body) {
  background: #f5f5f5;
}

.headerContainer {
  box-shadow: 0 0 0.25rem 0.0625rem rgba(4, 5, 5, .06), 0 0.0625rem 0.0625rem 0 rgba(4, 5, 5, .18);
  margin-bottom: 1px;
}

.header {
  display: flex;
  align-items: center;
  padding-inline: 2em;
  color: var(--ant-color-text);
  background-color: var(--ant-color-bg-container);
  height: 64px;
}

.footer {
  border-top: 1px solid var(--ant-color-border);
  background: var(--ant-color-bg-container);
  margin-top: auto;
  padding-inline: 2em;
}

.footerCopyright {
  text-align: center;
}

.logo {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: bolder;
  font-size: 16px;
  color: #333;
  margin-right: 1.5em;
}

.logoFooter {
  margin: 0;
}

.logo>img {
  height: 20px;
  width: 21px;
  margin-right: 0.5em;
}

.logo:hover,
.logo:focus,
.logo:active {
  color: #000;
}

.headerMobile {
  height: 50px;
  border-bottom: 1px solid var(--ant-color-fill-content);
  padding-inline: 1em;
}

.headerMobile .logo {
  margin-left: 0.5em;
}

.menuMobile {
  display: flex;
  justify-content: flex-end;
}

.menuMobile>a {
  line-height: 30px;
  padding-inline: 6px;
  color: rgba(0, 0, 0, 0.85);
  margin-inline: 6px;
  border-radius: var(--ant-border-radius);
}

.menuMobile>a:hover {
  background: var(--ant-color-fill);
}

.menuMobileActive {
  color: rgba(0, 0, 0, 0.85);
  background: var(--ant-color-fill-secondary);
}

.menuMobileSub {
  background: var(--ant-color-bg-container);
  line-height: 30px;
}

.menuMobileSub a {
  color: inherit;
}

.mainMenu::before {
  content: "";
  height: 20px;
  border-left: 1px solid var(--ant-color-fill);
  margin-right: 0.5em;
}

.mainMenu a {
  padding: 0.5em 1em;
  margin: 0 0.1em;
  border-radius: var(--ant-border-radius);
  color: var(--ant-color-text);
}

.mainMenu a:hover {
  background: var(--ant-blue-2);
}

.active {
  background: var(--ant-blue-1);
}

.rightMenu {
  margin-left: auto;
  display: flex;
  align-items: inherit;
  gap: 12px;
}

.subMenu {
  line-height: 50px;
  border-top: 1px solid var(--ant-color-split);
  padding: 0 1em;
}

.drawerMenu {
  display: none;
}

.drawerMenu>button {
  color: rgba(0, 0, 0, 0.9);
}

.content {
  padding: 2em;
}

.mobileContent {
  padding: 1em 1em 2em 1em;
}

.container {
  min-height: 100vh;
  max-width: 1600px;
  margin: 0 auto;
  background: #f9f9f9;
}

.mobileContainer {
  overflow-y: scroll;
}

@media (max-width: 920px) {
  .subMenu {
    padding: 0 0em;
  }

  .content {
    padding: 2em 1em;
  }

  .mobileContent {
    padding: 1em 1em 2em 1em;
  }

  .mainMenu span {
    display: none;
  }

  .mainMenu a.active span {
    display: inline-block;
  }
}


@media (max-width: 700px) {
  .logo {
    margin: 0 auto;
  }

  .mainMenu,
  .rightMenu {
    display: none;
  }

  .drawerMenu {
    display: block;
  }

  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer>div:last-child {
    margin-left: 0;
  }
}